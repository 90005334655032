import React, { useEffect, useState } from 'react';
import {
    Nav, NavItem, NavLink, TabContent, TabPane, Spinner,
} from 'reactstrap';
import classNames from 'classnames';
import fetchMethodRequest from '../../config/service';
import config from '../../config/config';
import dateFormats from '../UI/FormatDate/formatDate';
import '../../scss/component/tabs.scss';
import configImages from '../../config/configImages';
import { Link } from 'react-router-dom';

const HistoryView = (props) => {
    let [activeTab, setActiveTab] = useState('1');
    let [getComments, setGetComments] = useState([]);
    let [getAttachments, setGetAttachments] = useState([]);
    let [loading, setLoading] = useState(false);
    // let [historyData, setHistoryData] = useState([])

    const historyData = props.historyData;
    const type = props.type
    useEffect(() => {
        getCommentsData();
    }, [props.selectedId])

    let toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    let getCommentsData = async () => {
        setLoading(true);
        fetchMethodRequest('GET', `${type.toLowerCase()}/${props.selectedId}?types=[COMMENTS,ATTACHMENTS]`)
            .then(async (response) => {
                if (response && response.comments) {
                    let comments = response.comments;
                    setGetComments(comments)
                    setGetAttachments(response?.details?.image)
                    // setHistoryData(response?.history)
                    setLoading(false);
                }
            }).catch((err) => {
                return err
            })
    }

    return (
        <div className="tabs mt-4" style={{ backgroundColor: '#EAECEF', borderRadius: '5px' }}>
            <div className="tabs__wrap">
                <Nav tabs style={{ backgroundColor: 'white', borderColor: 'orange' }}>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1') }}>{'History'}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2') }}>{'Comments'}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3') }}>{'Attachments'}</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }}>
                    <TabPane tabId="1">
                        <div style={{ maxHeight: 400 }}>
                            {(historyData && historyData.length === 0) ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}>
                                    <Spinner color="dark" />
                                </div>
                            ) : (
                                historyData && historyData.length > 0 ? (
                                    historyData.map((item, i) => {
                                        return (
                                            <div key={i} className='row  p-2 m-0' >
                                                <div className="timeline__content col-sm-10">
                                                    <div className='row justify-content-between'>
                                                        <h5 className='col-sm-auto text-capitalize'>
                                                            {item.createdBy && item.createdBy.employee ?
                                                                item.createdBy.employee.displayName
                                                                : item.email}
                                                        </h5>
                                                        <h6 className='col-sm-auto'>{item?.created ? item.created : ""}</h6>
                                                        <p className='col-12' style={{ paddingTop: "5px" }}
                                                            dangerouslySetInnerHTML={{ __html: item.description }}>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="timeline__icon ms-3">
                                                    {item.createdBy?.employee?.photo ? (
                                                        <img
                                                            src={`${config.imgUrl}employee/${item.createdBy?.employee?.photo[0]}`}
                                                            // className="detailsImgStyle"
                                                            alt="Employee"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={configImages.defaultImg}
                                                            className="defaultImgStyle"
                                                            alt="Default"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
                                        No History found
                                    </div>
                                )
                            )}
                        </div>
                    </TabPane>

                    <TabPane tabId="2">
                        <div style={{ maxHeight: 600 }}>
                            {loading ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}>
                                    <Spinner color="dark" />
                                </div>
                            ) : (
                                getComments && getComments.length > 0 ?
                                    getComments.map((item, i) => {
                                        return (
                                            <div key={i} className='row  p-2 m-0'>
                                                <div className="timeline__contents col-sm-12">
                                                    <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                        <h5 className='col-sm-auto text-capitalize'>{item.createdByName}</h5>
                                                        <h6 className='col-sm-auto'>{dateFormats.formatDate(item.created, config.dateDayMonthFormat)}</h6>
                                                        <p className='col-12 mt-1' dangerouslySetInnerHTML={{ __html: item.comments }}></p>
                                                    </div>
                                                </div>
                                                {/* <div className="timeline__icon ml-3">
                                                    {item.createdBy.employee.photo ?
                                                    <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo}`} className='detailsImgStyle '
                                                    />
                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                    }
                                                </div> */}
                                            </div>
                                        )
                                    })
                                    : (
                                        <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
                                            No Comments found
                                        </div>
                                    )
                            )}
                        </div>
                    </TabPane>

                    <TabPane tabId="3">
                        <div style={{ maxHeight: 400 }}>
                            {loading ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}>
                                    <Spinner color="dark" />
                                </div>
                            ) : (
                                getAttachments && getAttachments.length > 0 ?
                                    getAttachments.map((item, i) => {
                                        return (
                                            <div key={i} className='row p-2 m-0'>
                                                <div className="timeline__contents col-sm-12">
                                                    <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                        <Link to={`${config.imgUrl}${props.type.toLowerCase()}/${item}`} target="_blank" style={{ textDecoration: "none" }}>
                                                            {item}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : (
                                        <div style={{ textAlign: 'center', padding: '20px', color: '#666' }}>
                                            No Attachments found
                                        </div>
                                    )
                            )}
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div >
    )

}
export default HistoryView;