const imagePath = '../assets/';
const configImages = {
  vid: require(`../assets/img/logo/video.png`),
  mp4: require(`../assets/img/logo/video.png`),
  avi: require(`../assets/img/logo/video.png`),
  mkv: require(`../assets/img/logo/video.png`),
  webm: require(`../assets/img/logo/video.png`),
  csv: require(`../assets/img/logo/csv.png`),
  xlsx: require(`../assets/img/logo/xlsx.png`),
  xls: require(`../assets/img/logo/xlsx.png`),
  ppt: require(`../assets/img/logo/ppt.jpg`),
  pdf: require(`../assets/img/logo/pdf.jpg`),
  json: require(`../assets/img/logo/json.jpg`),
  txt: require(`../assets/img/logo/txt.jpg`),
  docx: require(`../assets/img/logo/docx.jpg`),
  doc: require(`../assets/img/logo/docx.jpg`),
  defaultImg: require(`../assets/img/profile/defaultImg.png`),
  defaultUserImage: require(`../assets/img/profile/default_user_image.png`),
  employeesSampleXL: require(`../assets/files/scheduleSampleUpload.xlsx`),
  loginImage: require(`../assets/img/logo/logo.png`),
  loginSideImage: require(`../assets/img/logo/1702466066856_download (1).png`),
  // loginLogo: require(`../assets/img/logo/loginLogo.jpg`),
  loaderImage: require(`../assets/loader.gif`)

};
export default configImages;
