import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import DeleteFilterModal from '../CommonModals/DeleteFilterModal';
import fetchMethodRequest from '../../../config/service';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'primereact/button';
import config from '../../../config/config';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';


const FilterOperations = (props) => {

    const [myHideShow,setMyShowHide] = useState(false);
    const [filtFunc,setFiltFunc] = useState(null);
    const [chosenFilterName,setChosenFilterName]= useState(props.savedFilterName);
    const [filterInput,setFilterInput]=useState('')
    const [lengthCheck, setLengthCheck] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false);
  
    const filterFunctions = [
      { label: 'Save Filter', value: 'Save Filter' },
      { label: 'Discard Changes', value: 'Discard Changes' },
      { label: 'Clear Filter', value: 'Clear Filter' },
    ];

    const filterCriteria = { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' };
  
    const [filterNameOptions,setFilterNameOptions] =useState([]);
    const [filterNameDrop,setFilterNameDrop] =useState([]);
    const [deleteFilterModal,setDeleteFilterModal]=useState(false);
    const [filterToDelete,setFilterToDelete]=useState({})
    const [currentlyAppliedFilter,setCurrentlyAppliedFilter]=useState({})

    if (props.onRef) {
        props.onRef({
            getFilterNameOptions,
            setChosenFilterName
        });
    }

    useEffect(() => {
        getFilterNameOptions();
    }, []);

    async function getFilterNameOptions() {
        let url='filters';
        return await fetchMethodRequest('GET',url)
          .then(async(response)=>{
            //Not using now maybe usefull in the future
            // let sessionexpired=localStorage.getItem('sessionexpired')
            // if(sessionexpired=='true'){
            //   setSessionExpiryModal(true);
            // }
            let responseData=[];
            if(response){
              
              responseData=response.filters;
              let filtOptions=[];
              for(let i=0; i<responseData.length;i++){
                if(responseData[i].screenName==props.screenName){
                    filtOptions.push({ name: responseData[i].name, screenName: responseData[i].screenName, jsonQuery: responseData[i].jsonQuery, _id: responseData[i]._id, event: responseData[i].event });
                }
             }
              setFilterNameDrop(filtOptions)
            }
          })
    }

    const getSaveFilterTemplate = () => {
        if (myHideShow == false) {
            setMyShowHide(true);
            setFilterInput('');
        }
        else {
            setMyShowHide(false);
        }
    }

    const handleFilterFunction = (e) => {
        setFiltFunc(e.value);
        if (e.value == 'Save Filter') {
            if (filterInput.length > 0 && lengthCheck == true) {
                handleSaveFilter(filterInput)
            }
            else {
                setFiltFunc('');
                setErrorMsg(true);
            }
        }
        if (e.value == 'Discard Changes') {
            props.getDataFromServer(currentlyAppliedFilter);
            setMyShowHide(false);
            setErrorMsg(false);
        }
        if (e.value == 'Clear Filter') {
            if (props.defaultCriteria) {
                props.setFilters(null);
                props.getDataFromServer(props.saveCriteria);
            } else {
                props.setFilters(null);
                props.getDataFromServer(filterCriteria, 'clear-all-filters');
            }
            setErrorMsg(false);
            setMyShowHide(false);
            setChosenFilterName(''); 
        }
        setTimeout(() => {
            setFiltFunc('')
        }, 500);
        setFilterInput('')
    }

    const handleSaveFilter = (filtName) => {

        let name = filtName;
        let sname = props.screenName;
        let filtOptions = filterNameOptions

        filtOptions.push({ name: name, screenName: sname, jsonQuery: props.saveCriteria })
        setFilterNameOptions(filtOptions)
        submitNewFilter(filterNameOptions);
    }

    const submitNewFilter = () => {
        let url = 'filters'

        let body = filterNameOptions[0];
        body['event'] = props.filterEvent;
        return fetchMethodRequest('POST', url, body)
            .then(async (response) => {
                //Not using now maybe usefull in the future
                // let sessionexpired = localStorage.getItem('sessionexpired')
                // if (sessionexpired == 'true') {
                //     setSessionExpiryModal(true);
                // }
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                    props.setFilters(null);
                    props.setGlobalSearch('');
                    await props.getDataFromServer(filterCriteria);
                    await setMyShowHide(false);
                    await getFilterNameOptions();
                    await setFilterNameOptions([]);
                }
                else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error')
                }
            }).catch((err) => {
                return err;
            })
    }

    const handleApplyChosenFilter = (e) => {

        let jsonQ, event = {}
        for (let i = 0; i < filterNameDrop.length; i++) {
            if (filterNameDrop[i].name == e.value.name) {
                jsonQ = filterNameDrop[i].jsonQuery;
                event = filterNameDrop[i].event;
            }
        }
        setCurrentlyAppliedFilter(jsonQ);
        setChosenFilterName(e.value);
        props.setFilters(event);
        props.setGlobalSearch(jsonQ.globalSearch ? jsonQ.globalSearch.value : '');
        props.getDataFromServer(jsonQ);
        props.getCriteriaOfSavedFilt(jsonQ);
        props.setSavedFilterName(e.value);
    }

    const handleFilterInputName = async (e) => {
        await setFilterInput(e.target.value)
        if (e.target.value.length == 0) {
            await setLengthCheck(false)
        }
        else {
            setErrorMsg(false)
            await setLengthCheck(true)
        }
    }

    const discardFilter = (e, option) => {
        e.stopPropagation();
        setDeleteFilterModal(true);
        setFilterToDelete(option);
    }

    const deleteSelectedFilter = (type) => {
        if (type == 'no') {
            setDeleteFilterModal(false);
        }
        if (type == 'yes') {
            let del = filterToDelete._id
            let url = `filters/${del}`
            fetchMethodRequest('DELETE', url)
                .then(async (response) => {
                    if (response && response.respCode) {
                        showToasterMessage(response.respMessage, 'success');
                        await props.getDataFromServer(filterCriteria);
                        await getFilterNameOptions();
                        props.setFilters(null);
                        props.setGlobalSearch('');
                    }
                    else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                });
            setDeleteFilterModal(false);
        }
    }

    const getDeleteFilterModal = () => {

        return (
            <DeleteFilterModal
                deleteFilterModal={deleteFilterModal}
                setDeleteFilterModal={setDeleteFilterModal}
                deleteSelectedFilter={deleteSelectedFilter}
            />
        )
    }

    const customItemTemplate = (option) => {
        return (
            <div className="p-clearfix d-flex justify-content-between w-100 mt-1">
                <div style={{ fontSize: '14px', float: 'left' }}>{option.name}</div>

                <span>
                    <FontAwesomeIcon
                        className='genderIconAlignment'
                        color='white'
                        icon='trash-alt'
                        data-toggle="tool-tip"
                        title="Delete"
                        style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                        onClick={(e) => discardFilter(e, option)}
                    />
                </span>
            </div>
        );
    };

    return (
        <>
            {myHideShow == true &&
                <div style={{ lineHeight: 1.1  }}>
                    <InputText
                        className={lengthCheck == false ? "p-invalid height-button" : "height-button"}
                        value={filterInput}
                        style={{width:'150px'}}
                        onChange={(e) => { handleFilterInputName(e) }}
                    />
                    {errorMsg ?
                        <span className="form__form-group-error">{'Please Fill the Input Field'}</span>
                        : null
                    }
                </div>
            }
            <Button
                color="secondary"
                size="small"
                className='button-add'
                onClick={() => getSaveFilterTemplate()}>
                <FontAwesomeIcon
                    icon="filter"
                    size='sm'
                    data-toggle="tool-tip" 
                    title="Filter Operations"
                    color={config.templateColor}
                />

            </Button>
            {myHideShow == true &&
                <span>

                    <Dropdown
                        // style={{ minWidth: '10%', lineHeight: 1.1 , height: '40px'}}
                        style={{ height: '2rem' }}
                        className='mr-3'
                        name='filterFunction'
                        value={filtFunc}
                        options={filterFunctions}
                        optionLabel='label'
                        placeholder='Filter functions'
                        onChange={(e) => handleFilterFunction(e)}
                    />
                </span>
            }
            <span className='mr-5'>

                <Dropdown
                    style={{ height: '2rem' }}
                    className='mr-3'
                    name='filterName'
                    value={chosenFilterName}
                    options={filterNameDrop}
                    optionLabel='name'
                    placeholder='Saved Filters'
                    onChange={(e) => handleApplyChosenFilter(e)}
                    itemTemplate={customItemTemplate}
                />
            </span>
            
            {deleteFilterModal ? getDeleteFilterModal() : null}
        </>
    );
}
export default FilterOperations;